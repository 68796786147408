import React, {useEffect, useRef, useState} from 'react';
import ReactToPrint from 'react-to-print';
import {PrinterFill,} from 'react-bootstrap-icons';
import {Button, Col, Row} from 'react-bootstrap';
import {calSyring, calSyringSize2, OptionSelects} from '../functions/utils';
import { format } from 'date-fns';
import th from 'date-fns/locale/th';
import {QRCodeSVG} from 'qrcode.react';
import {DrugMac, OptionSelect, OrderTransaction, ScsGem} from '../type';
import Moment from 'react-moment';
import moment from 'moment';
import {convertTextToEnglishQwerty, convertTextToThaiQwerty} from "../functions/convertText";
import {useContexts} from "../context/AppContext";
import {apiService} from "../functions/api-service";

type Props = {
  data: OrderTransaction;
  contentType:string
  site:string | undefined;
};

class ContentQrChemo extends React.Component<Props>  {
    render() {

      const data: OrderTransaction = {...this.props.data}
      const site = this.props.site

      if(data?.orderDate){
        data.orderDate = new Date(data.orderDate);
      }
        let pull= data.chemoPull != '0.0' ? data.chemoPull: data.chemoPullGem;
        let nss = (data?.scsId && data?.med && data?.med?.scsList.length > 0 && data.med.scsList.find(e => e.id === data.scsId)?.scs?.name) ??''
        let qrNss = (nss??'')
        let total_val= 0
        if (typeof qrNss === "string" && pull) {
            let nssVal =(data?.scsId && data?.med && data?.med?.scsList.length > 0 && data.med.scsList.find(e => e.id === data.scsId)?.scs?.solvent) ?? 0;
            total_val = parseFloat(String(nssVal)) + parseFloat(pull);
        }

        nss = nss != '' ? 'in '+nss :''

        let scs_gem_machine_id: string = '',scs_machine_id: string = '',stable_timetxt = '',temperature = '', stable_type= '',stable_time='',scs_code='',expHr: string | number = '';

        if(data?.scsId && data.med){
            stable_time = ( data.med.scsList.find(e => e.id === data.scsId)?.stableTime) ??''
            temperature = ( data.med.scsList.find(e => e.id === data.scsId)?.temperature) ??''
            stable_type = ( data.med.scsList.find(e => e.id === data.scsId)?.stableType) ??''
            scs_code = ( data.med.scsList.find(e => e.id === data.scsId)?.scsCode) ??''
            scs_machine_id = (( data.med.scsList.find(e => e.id === data.scsId))?.scs?.machine?.itemId)??''
            expHr =  (stable_type === 'ชัวโมง' ? stable_time : parseInt(stable_time) * 24)

            temperature = temperature != '' ? 'เก็บรักษาที่อุณหภูมิ '+temperature+' องศา' :''
            stable_timetxt = stable_time != '' ? 'ใช้ภายใน '+stable_time+' '+stable_type :''
        }
        // if(data?.scsGemId && data.med){
        //     scs_gem_machine_id = (( data.scsGemList.find(e => e.id === data.scsGemId))?.machine?.itemId)??''
        // }

        let min = moment().format('HH:mm')
        let temp = moment(data.orderDate).format('DD/MM/YYYY')+' '+min
        let mfg = moment(temp,'DD/MM/YYYY HH:mm').format('DD/MM/YYYY HH:mm');
        let units: moment.unitOfTime.DurationConstructor = ( stable_type === 'ชัวโมง' ? 'hours' :'day');
        let exp = moment(mfg,'DD/MM/YYYY HH:mm').add(stable_time, units).format('DD/MM/YYYY HH:mm');

        let fullname = convertTextToEnglishQwerty(data?.person?.fullName.toString()??'',site)
        // let  xold = data.amountOld ?data.dosage1+'@'+data.dosageOld+' ml':''
        // $text = $data_temp['id'].','.$data_temp['full_name'].','.$data_temp['trade_name'].','.$xold.','.$data_temp['hm_qty_old'].','.$data_temp['item_dosage'].','.$data_temp['hm_qty'].','.$data_temp['item_dosage2'].','.$data_temp['hm_qty2'].','.$data_temp['chemo_pull'].','.$data_temp['chemo_pull_gem'].','.$data_temp['solvent_scs'].','.$data_temp['solvent_scs_unit_old'].','.$data_temp['scs_name'].','.$data_temp['m_medical_name'].','.$data_temp['order_date'];
        let chemoPull  = data?.scsGemMacId == '' && data.chemoPullGem ? data.chemoPullGem : data.chemoPull;
        let chemoPullGem  = data?.scsGemMacId != '' ? data.chemoPullGem: '0.00';
        let qrValue = data.orderID+','+(fullname)+','
            +(data?.medOld?.machine?.itemId??'')+','+(data.amountOld??'')+','+(data?.med?.machine?.itemId??'')+','+data.amount1+','
            +(data?.med2?.machine?.itemId??'')+','+(data.amount2??'')+','+(chemoPull??'')+','+(chemoPullGem??'')+','
            +(data?.scsGemMacId??'')+','+(data.scsGemUnitOld??'')+','+scs_machine_id+','+(data.method?.id??"") +','
            + (data?.orderDate ? format(data?.orderDate,  "Y-MM-dd") : '')+','+(data?.person?.hn.toString())
            +','+(data?.med?.machine?.density??'')+','+(data?.med2?.machine?.density??'');

        let qrValue2 = data.orderID+',*'+(fullname)+'*,'
            +(data?.medOld?.machine?.itemId??'')+','+(data.amountOld??'')+','+(data?.med?.machine?.itemId??'')+','+data.amount1+','
            +(data?.med2?.machine?.itemId??'')+','+(data.amount2??'')+','+(chemoPull??'')+','+(chemoPullGem??'')+','
            +(data?.scsGemMacId??'')+','+(data.scsGemUnitOld??'')+','+scs_machine_id+','+(data.method?.code =='99' ? data.method?.code : data.method?.id??"") +','
            + (data?.orderDate ? format(data?.orderDate,  "Y-MM-dd") : '')+','+(data?.person?.hn.toString())
            +','+(data?.med?.machine?.density??'')+','+(data?.med2?.machine?.density??'')+','+(expHr??'');

        let componentToRender;
        switch (site) {
            case '1':
                componentToRender = (
                    <Row className={"thsarabunnew"} style={{margin: "5px",fontSize:"15px",fontWeight:"500"}}>
                        <Col  className={"col-12 px-1"}> &nbsp; </Col>
                        <Col  className={"col-12 px-1 mb-2"}> &nbsp;</Col>
                        <Col className={"col-9 px-1 fw-bold"} >{data?.person?.hn+' '+data?.person?.fullName}</Col>
                        <Col className={"col-3 px-1 fw-bold"} style={{fontSize:"12px",textAlign:"right"}}>{data.dept ??""}</Col>
                        <Col className={"col-9 px-1 fw-bold "} style={{fontSize:"13px"}}>
                            {data?.med?.name}
                            <span style={{fontSize:"12px"}}>{' '+data.perTime+'mg ('+parseFloat(pull as string).toFixed(2)+'ml)'}</span>
                            {/*{'('+data?.med?.strength+'mg/'+data?.med?.solvent+'ml)'}*/}
                        </Col>
                        <Col className={"col-3 px-1  fw-bold "} style={{fontSize:"12px",textAlign:"right"}}>
                            {data?.orderDate && (format(data?.orderDate,  "dd MMM", {locale: th}) + ' ' +
                                (Number(format(data?.orderDate,  "Y")) + 543).toString())}
                        </Col>
                        <Col  className={"col-12 px-1 fw-bold"} style={{fontSize:"13px",}}>
                            {''+nss+" "+(data.method?.name??"") + " " + (data.time?.name??"")}
                            {/*{data?.med?.name+'('+data?.med?.strength+'mg/'+data?.med?.solvent+'ml)'+' x '+data.amount1}*/}
                            {/*{ data?.med2? (', '+data?.med2?.name+'('+data?.med2?.strength+'mg/'+data?.med2?.solvent+'ml)'+' x '+data.amount2):""}*/}
                            {/*{ data?.medOld? (', '+data?.medOld?.name+'('+data?.medOld?.strength+'mg/'+data?.medOld?.solvent+'ml)'+' x '+data.amountOld):""}*/}
                        </Col>

                        {/*<Col  className={"col-12 px-1"} style={{fontSize:"12px"}}>*/}
                        {/*    {'('+data.perTime+'mg/'+pull+'ml) '+nss+" "+(data.method?.name??"") + " " + (data.time?.name??"")}*/}
                        {/*</Col>*/}

                        <Col  className={"col-4 px-1"} style={{fontSize:"8px"}}>

                            <Col className={"text-center px-1"} style={{minHeight: "75px"}}>
                                {/*<QRCode*/}
                                {/*    size={256}*/}
                                {/*    style={{ height: "auto", maxWidth: "100%", width: "100%" }}*/}
                                {/*    value={qrValue}*/}
                                {/*    viewBox={`0 0 256 256`}*/}
                                {/*/>*/}
                                <QRCodeSVG
                                    id={"qr-code-voucher"}
                                    size={256}
                                    value={qrValue}
                                    // renderAs={"png"}
                                    bgColor={"#ffffff"}
                                    includeMargin={true}
                                    style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                                />
                            </Col>
                            <Col className={"text-center "} >{data.orderID}</Col>
                        </Col>

                        <Col  className={"col-8 px-1"} style={{fontSize:"13px"}}>
                            <Col>{calSyring(pull ?? '', data.method?.name ?? '')}</Col>
                            <Col>
                                <span className={"fw-bold"}>MFG: </span>{mfg}
                            </Col>
                            <Col>
                                <span className={"fw-bold"}>EXP: </span>{exp}
                            </Col>
                            <Col><span className={""}>{temperature}</span></Col>
                            <Col className={"fw-bold"}>{(data.noDay ? ' Day: '+data.noDay  : '')+ (data.noInDay ? ' ขวดที่  '+data.noInDay  : '')}</Col>

                            <Col style={{fontSize:"12px"}}>
                                <span className={"fw-bold"} style={{fontSize:"12px"}}>คำเตือน : </span>{data.med?.note}
                            </Col>
                        </Col>
                    </Row>
                );
                break;
            case '2':
                componentToRender = (
                    <Row className={"thsarabunnew pagebreak"} style={{margin: "5px",fontSize:"15px",fontWeight:"500"}}>
                        <Col  className={"col-12 px-1"}> &nbsp;</Col>
                        <Col className={"col-11 px-0 py-0 "} style={{fontSize:"12px",textAlign:"right"}}>
                            {data.dept ??""}
                        </Col>
                        <Col className={"col-1 px-0 py-0 "} style={{fontSize:"12px",textAlign:"right"}}>&nbsp;</Col>
                        <Col className={"col-8 px-1 fw-bold"} style={{fontSize:"15px"}}>
                            {data?.person?.hn+' '+data?.person?.fullName}
                        </Col>
                        <Col className={"col-3 px-1 "} style={{fontSize:"12px",textAlign:"right"}}>
                            {data?.orderDate && (format(data?.orderDate,  "dd MMM", {locale: th}) + ' ' +
                                (Number(format(data?.orderDate,  "Y")) + 543).toString())}
                        </Col>
                        <Col className={"col-12 px-1 fw-bold "} style={{fontSize:"13px"}}>
                            {data?.med?.name+' '+data.perTime+' mg (ปริมาตรยา '+parseFloat(pull as string).toFixed(2)+' ml)'}
                        </Col>
                        <Col className={"col-12 px-1 fw-bold "} style={{fontSize:"13px"}}>
                                {'(Total volume   '+(total_val).toFixed(2)+' ml)'}
                        </Col>
                        <Col  className={"col-12 px-1 fw-bold"} style={{fontSize:"13px",}}>
                            {''+nss+" "+(data.method?.name??"") + " " + (data.time?.name??"")}
                        </Col>
                        <Col  className={"col-4 px-1"} style={{fontSize:"8px"}}>

                            <Col className={"text-center px-1"} style={{minHeight: "75px"}}>
                                <QRCodeSVG
                                    id={"qr-code-voucher"}
                                    size={256}
                                    value={qrValue2}
                                    bgColor={"#ffffff"}
                                    includeMargin={true}
                                    style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                                />
                            </Col>
                            <Col className={"text-center fw-bold"} style={{fontSize:"9px"}}>{data.orderID}</Col>
                        </Col>

                        <Col  className={"col-8 px-1"} style={{fontSize:"14px"}}>
                            <Col>{calSyringSize2(pull ?? '', data.method?.name ?? '')},Med quantity: {data.amount1}</Col>
                            <Col>
                                <span className={"fw-bold"}>MFG:
                                    {moment(data.orderDate).format('DD/MM/YYYY ')}
                                    {data.mfgDate ? data.mfgDate :min}
                            </span>
                            </Col>
                            <Col>
                                <span className={"fw-bold"}>EXP:
                                    {data.mfgDate ?
                                        moment(exp,'DD/MM/YYYY HH:mm').format('DD/MM/YYYY ')+ data.mfgDate :
                                        moment(exp,'DD/MM/YYYY HH:mm').format('DD/MM/YYYY HH:mm')}
                                </span>
                            </Col>
                            <Col>
                                <span className={""}>{temperature}</span>
                            </Col>

                            <Col className={"fw-bold"}>{(data.noDay ? ' Day: '+data.noDay  : '')+ (data.noInDay ? ' ขวดที่  '+data.noInDay  : '')}</Col>

                            <Col style={{fontSize:"8px"}}>
                                <span className={"fw-bold"} style={{fontSize:"12px"}}>คำเตือน : </span>{data.med?.note}
                            </Col>
                        </Col>
                    </Row>
                );
                break;
            default:
                componentToRender = <Row>error call admin</Row>; // or any other default value
        }
        return componentToRender;
            
    }
}

const BtnPrintQrChemo = ({data,site }: Props) => {
  const componentRef = useRef(null);
    return (
    <div className={""}>
      <ReactToPrint
        trigger={() => <Button variant="warning" size="sm"><PrinterFill /> </Button>}
        content={() => componentRef.current}
        // copyStyles={false}
         pageStyle={`@media print {
          @page {
            size:  3.54in 2.75in;          
          }
        }`}
      />
      <div className={"hide"} style={{display:"none"}}>
      {/*<div>*/}
        <ContentQrChemo ref={componentRef} data={data} site={site} contentType={'QrCode'}/>
      </div>
    </div>
  );
};

export default BtnPrintQrChemo;