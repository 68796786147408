import { useEffect, useState } from "react";
import {Button, Container, Nav, Table} from "react-bootstrap";
import {
  CartCheck,
  ChatSquareDots, CheckCircle,
  Recycle,
} from "react-bootstrap-icons";
import { apiService } from '../../functions/api-service';
import Swal from 'sweetalert2';
import { Alert } from '../../functions/utils';
import { useContexts } from "../../context/AppContext";
import {ChangeEvent, Department, OrderDetail, OrderTransaction} from "../../type";
import ModalDept from "../../components/modalDept";
import {format, isValid, parse, parseISO} from "date-fns";
import th from "date-fns/locale/th";
import FilterOrderTran from "../../components/filterOrderTran";
import ModalOrderTransaction from "../../components/modalOrderTran";
import {useNavigate} from "react-router-dom";

export default function SynReport() {
  const { isLoad, isLoaded } = useContexts();
  const { profile } = useContexts();
  let checkHn = "";

  const navigate = useNavigate();
  useEffect(() => {
    if((profile?.sub !== '0')){
      navigate("/")
    }
  }, []);

  const statusMachine = [
    { value: "1", lable: "Ready" },
    { value: "2", lable: "Running" },
    { value: "3", lable: "Finished" },
    { value: "4", lable: "Error" },
  ];
  const statusOrder = [
    { value: "1", lable: "รับแล้ว" },
    { value: "2", lable: "เช็คยา" },
    { value: "3", lable: "จ่ายยา" },
    { value: "99", lable: 'Error' },
  ];
  const [orderTranList, setOrderTranList] = useState<Array<OrderTransaction>>([]);
  const [searchOrderTran, setSearchOrderTran] = useState({
    person: "",
    medName: "",
    orderDate: new Date(),
    statusMachine: "",
    statusOrder: "",
    dept: "",
  });

  const [openFormOrderTran, setOpenFormOrderTran] = useState<{
    show: boolean;
    showError: boolean;
    data: OrderTransaction | null;
    saved: boolean;
  }>({ show: false,showError: false, data: null, saved: false });

  const [openFormRemedOrderTran, setOpenFormRemedOrderTran] = useState<{
    show: boolean;
    data: OrderTransaction | null;
    saved: boolean;
  }>({ show: false, data: null, saved: false });

  const filteredItems = orderTranList && orderTranList.filter(
      (tran) => {

        let result = (
            (tran.med && tran.med?.name.includes(searchOrderTran.medName)) &&
            ((tran.dept && tran.dept?.includes(searchOrderTran.dept)) || tran.dept == null) &&

            (tran.person && tran.person?.fullName.includes(searchOrderTran.person)
                || tran.person && tran.person?.hn.includes(searchOrderTran.person)
                || tran.orderID?.includes(searchOrderTran.person)));

        if(searchOrderTran.statusMachine && result){
          result = tran.statusMachine === searchOrderTran.statusMachine
        }
        if(searchOrderTran.statusOrder && result){
          result = tran.statusOrder === searchOrderTran.statusOrder
        }
        return result;
      }
  );

  useEffect(() => {
    getOrderTranList().catch(console.error);
  }, [searchOrderTran.orderDate]);



  const getOrderTranList = async () => {
    isLoad();
    setOrderTranList(
        await apiService.orderTranList({type:'report',createAt: format(new Date(searchOrderTran.orderDate), "yyyy-MM-dd")})
    );
    isLoaded();
  };

  const  btnOrderStatus =(r:OrderTransaction) =>{
    if(r.statusOrder == '1'){
      return (<Button title={"set to check"} variant="success" size="sm" onClick={() => setOrderStatus(r?.orderID,"2").then()}>
        <CheckCircle />
      </Button>)

    }else if(r.statusOrder == '2'){
      return (<Button title={"set to pay"} variant="primary" size="sm" onClick={() => setOrderStatus(r?.orderID,"3").then()}>
        <CartCheck />
      </Button>)
    }else{
      return ('')
    }
  };
  const setOrderStatus =async (orderId: string | undefined, status: string) => {
    const res = await apiService.updateOrderStatus(orderId,status);
    if ( res.success === true ) {
      getOrderTranList().then();
    }
    Alert(res.success, res.message);
  }

  const handleChange = ( e: ChangeEvent) => {
    setSearchOrderTran((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };


  const trHeader = (r: OrderTransaction, i: number) => {

    return(
        <tr className={"bg-primary bg-opacity-25"} key={i.toString() +'_'+ r?.person?.hn}>
          <td colSpan={13}>
            <div className={"row"}>
              <div className={"col-3"}>
                <strong>HN : </strong> {r?.person?.hn} {r?.person?.fullName}
              </div>
              <div className={"col-3"}>
                <strong>Treatment : </strong> {r.treatmentName}{" "}
                <strong>Cycle : </strong>{r.cycle}{" "}
                <strong>Day : </strong>{r.day}
              </div>
              <div className={"col-3"}>
                <strong>วันที่สร้างรายการ</strong>{" "}
                {r.createdAt && format(new Date(r.createdAt), "dd MMMM yyyy", {locale: th})}
              </div>
              <div className={"col-3 text-end"}>
              </div>
            </div>
          </td>
        </tr>
    )};

  const updateOrderDetailAll =async (OrderTran: OrderTransaction[]) => {
    isLoad();
    await Promise.all(
       filteredItems.map(async (r, i) => {
         const site = profile?.site || '';
         const res = await apiService.saveOrderDetail(site,r.orderID, r.med?.code);
       })
    )

    setOrderTranList(await apiService.orderTranList({type:'report',createAt: format(new Date(searchOrderTran.orderDate), "yyyy-MM-dd")}));
    isLoaded();
  }

  const updateOrderDetail =async (site: string | undefined, orderId: string | undefined, mdeCode: string | undefined,) => {
    isLoad();
    const res = await apiService.saveOrderDetail(site,orderId,mdeCode);
    if ( res.success === true ) {
      setOrderTranList(
          await apiService.orderTranList({type:'report',createAt: format(new Date(searchOrderTran.orderDate), "yyyy-MM-dd")})
      );
    }
    isLoaded();
    Alert(res.success, res.message);
  }

  const uniquePerson = Array.from(
      new Set(filteredItems.map((e) => e.person?.hn))
  );

  const uniqueTreatmentName = Array.from(
      new Set(filteredItems.map((e) => e.treatmentName))
  );

  const showDetail = (detailTxt:any,sumTxt:any) => {
    Swal.fire({
      title:'รายละเอียด',
      html: `
<table class="table table-bordered">
      <thead>
        <tr>
          <th>#</th>
          <th>Sum Weight(mg)</th>
          <th>Sum Volume(ml)</th>
          <th>Density <small>(average)</small></th>
          <th>Error <small>(((weight/dense)-volume)*100/volume)</small></th>
        </tr>
      </thead>
      <tbody>
       ${sumTxt}
      </tbody>
    </table>
    <br><hr><br>
    <table class="table table-bordered">
      <thead>
        <tr>
          <th>#</th>
          <th >Medication</th>
          <th>Weight Before(mg)</th>
          <th>Weight After(mg)</th>
          <th>Weight(mg)</th>
          <th>Volume(ml)</th>
          <th>Density</th>
          <th>Error <small>(((weight/dense)-volume)*100/volume)</small></th>
        </tr>
      </thead>
      <tbody>
       ${detailTxt}
      </tbody>
    </table>
        `,
      width:'85%',
      confirmButtonText: 'Close'
    });
  };

  const trDetail = (r: OrderTransaction, i: number) => {
    const detail =  r.orderDetail && r.orderDetail.sort(((a, b) => (parseInt(b.itemid) - parseInt(b.pullindex)) - (parseInt(a.itemid) - parseInt(a.pullindex) ) ));


    const cutTimeString = (dateTime:string) => {
      console.log(dateTime)
      const parts = dateTime.split(' ');
      if (parts.length < 2) {
        return '';
      }
      const timePart = parts[1];
      return timePart.slice(0, 5);
    };

    const detailTxt =detail && detail.map((x, l) => {
      let color = (parseFloat(x.error ?? '-') > -5 && parseFloat(x.error ?? '-') < 5)?'green' : 'red'
      return `<tr>
          <td>${x.pullindex}</td>
          <td>${x.med}</td>
          <td>${x.weightBefore}</td>
          <td>${x.weightAfter}</td>
          <td>${x.weight}</td>
          <td>${x.volume}</td>
          <td>${x.density}</td>
          <td><b style="color:${color}"> ${x.error}</b></td>
        </tr>`
    });
    let scolor = (parseFloat(r.syrError ?? '-') > -5 && parseFloat(r.syrError ?? '-') < 5)?'green' : 'red'
    let mcolor = (parseFloat(r.medError ?? '-') > -5 && parseFloat(r.medError ?? '-') < 5)?'green' : 'red'
    let sumM = r.medSum ? JSON.parse(r?.medSum) :'-';
    let sumS = r.syringeSum ? JSON.parse(r.syringeSum) :'-';
    let sumTxt =  `<tr>
          <td>Syringe</td>
          <td>${sumS != '-' ? sumS.sumweight : '-'}</td>
          <td>${sumS != '-' ? sumS.sumvolume : '-'}</td>
          <td>${r.densityAvg}</td>
          <td><b style="color:${scolor}"> ${r.syrError}</b></td>
        </tr>
        <tr>
          <td>Medication</td>
          <td>${sumM != '-' ? sumM.sumweight : '-'}</td>
          <td>${sumM != '-' ? sumM.sumvolume : '-'}</td>
          <td>${r.densityAvg}</td>
          <td><b style="color:${mcolor}"> ${r.medError}</b></td>
        </tr>`

    return (<tr key={i}>
      <td>{r.orderID}
      </td>
      <td>
        {r.chemoPull && parseFloat(r.chemoPull) > 0 && parseFloat(r.chemoPull as string).toFixed(2)}
        {r.chemoPullGem && parseFloat(r.chemoPullGem) > 0 && parseFloat(r.chemoPullGem as string).toFixed(2)}
        <br/>
        ({r.perTime && parseFloat(r.perTime) > 0 && parseFloat(r.perTime as string).toFixed(2)} mg)
      </td>
      <td>{r.med?.name}
        <div>
          <small>Day : {r.noDay}</small>
          <small> ขวดที่ {r.noInDay}</small></div>
      </td>
      <td>
        {r?.scsId && r?.med && r?.med?.scsList.length > 0 &&
            r.med.scsList.find(e => e.id === r.scsId)?.scs?.name}
      </td>
      <td>{(r.method?.name ?? '') + " " + (r.time?.name ?? '')}</td>
      <td className={"text-center"}>
        {r.orderDate && format(new Date(r.orderDate), "dd MMM yyyy", {locale: th})}
      </td>
      <td className={"text-center"}>
        {r.dept && r.dept}
      </td>
      <td className={"text-center"}>
        {r.orderDetail && r.orderDetail.length > 0 && (
            <>
              {r.startAt && format(new Date(r.startAt), "dd MMM yyyy", {locale: th})}
              {r.startAt &&  ' '+cutTimeString(r.startAt.toString())}
              {r.startAt && ' - '}
              {r.endAt && format(new Date(r.endAt), "dd MMM yyyy", {locale: th})}
              {r.endAt && ' '+cutTimeString(r.endAt.toString())}
            </>
        )}

      </td>
      <td>
        {(r.orderDetail && r.orderDetail?.length > 0) && (
            <>
              <div className="d-grid gap-2 ">

                {r.medError && <Button size="sm" style={{textAlign: 'left'}}
                                       variant={(parseFloat(r.medError ?? '-') > -5 && parseFloat(r.medError ?? '-') < 5) ? 'outline-success' : 'outline-danger'}>Medication
                  : {r.medError} %</Button>}
                {r.syrError && <Button size="sm" style={{textAlign: 'left'}}
                                       variant={(parseFloat(r.syrError ?? '-') > -5 && parseFloat(r.syrError ?? '-') < 5) ? 'outline-success' : 'outline-danger'}>Syringe
                  : {r.syrError} %</Button>}
              </div>
              <div className={'mt-2 d-grid'}>
                {r.medError && <Button size="sm" variant='outline-primary'
                                       onClick={() => showDetail(detailTxt, sumTxt)}>Detail</Button>}
              </div>
            </>
        )}
      </td>
      <td>{r.error}</td>
      <td>{statusMachine.find(e => e.value === r.statusMachine)?.lable}</td>
      <td>{statusOrder.find(e => e.value === r.statusOrder)?.lable}
        {btnOrderStatus(r)}
      </td>
      <td className={"text-center"}>
        <Button
            size="sm"
            onClick={() =>
                updateOrderDetail(profile?.site, r.orderID, r.med?.code)
            }
        >
          <Recycle/> Update
        </Button>
        {" "}
        <Button
            variant="danger"
            size="sm"
            onClick={() =>
                setOpenFormOrderTran({
                  show: true,
                  showError: true,
                  data: r,
                  saved: false,
                })
            }
        >
          <ChatSquareDots/> Error
        </Button>
      </td>
    </tr>)
  }

  return (
      <>
        <FilterOrderTran
            handleChange={handleChange}
            openFormOrderTran={() => setOpenFormOrderTran({showError: false,show: true, data: null, saved: false })}
            openFormRemedOrderTran={() => setOpenFormRemedOrderTran({ show: true, data: null, saved: false })}
            orderDate={searchOrderTran.orderDate}
            dept={searchOrderTran.dept}
            type='report'
            updateOrderDetailAll={() => updateOrderDetailAll(filteredItems)}
        />
        <Container className={"mw-100"}>
          <Table striped bordered hover responsive={"xl"}>
            <thead>
            <tr>
              <th>ID</th>
              <th>ดูด</th>
              <th>รายการยา</th>
              <th>น้ำเกลือผสม</th>
              <th>วิธีใช้ยา</th>
              <th>วันที่สั่ง(Order Date)</th>
              <th>แผนก</th>
              <th>วันที่ผสม</th>
              <th>รายละเอียด</th>
              <th>Error</th>
              <th>สถานะ Machine</th>
              <th>สถานะ Order</th>
              <th>Action</th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td colSpan={13} className={"bg-info bg-opacity-75 p-1"}
                  // style={{textAlign:"right"}}
              >
              </td>
            </tr>

            <tr>
              <td colSpan={3} className={"bg-success bg-opacity-50 p-3"}
              >
                <strong>จำนวนผู้ป่วย (HN) {uniquePerson.length}</strong>
              </td>
              <td colSpan={4} className={"bg-success bg-opacity-50 p-3"}
              >
                <strong>จำนวนตำรับยา (Treatment) {uniqueTreatmentName.length}</strong>
                {/*<strong> จำนวนรายการทั้งหมด {filteredItems.length}</strong>*/}
              </td>
              <td colSpan={6} className={"bg-success bg-opacity-50 p-3"}
                  style={{textAlign: "right"}}
              ><strong>จำนวนตำรับยา (ID) {filteredItems.length}</strong>
              </td>
            </tr>

            <>
              {filteredItems && filteredItems.map((r, i) => {
              if (checkHn !== r?.person?.hn) {
                checkHn = r?.person?.hn ? r.person.hn : '';
                return <>{trHeader(r, i)} {trDetail(r, i)}</>;
              }else{
                return <>  {trDetail(r, i)}</>;
              }
            })}
              </>
            </tbody>
          </Table>
          <ModalOrderTransaction
              show={openFormOrderTran.show}
              showError={true}
              data={openFormOrderTran.data}
              handleClose={() =>
                  setOpenFormOrderTran((prev) => ({
                    ...prev,
                    show: false,
                  }))
              }
              saved={() =>
                  setOpenFormOrderTran((prev) => ({
                    ...prev,
                    saved: true,
                  }))
              }
          />
        </Container>

      </>
  );
}
