import { useEffect, useState } from "react";
import {Button, Container, Nav, Table} from "react-bootstrap";
import {
    ChatHeart, ChatSquareDots, Check, Check2Square, CheckAll,
    Pass,
    Pen,
    People,
    PersonCheckFill,
    PersonFillAdd,
    PersonFillDash,
    Trash, Wallet
} from "react-bootstrap-icons";
import { apiService } from '../../functions/api-service';
import Swal from 'sweetalert2';
import { Alert } from '../../functions/utils';
import { useContexts } from "../../context/AppContext";
import {User} from "../../type";
import ModalUser from "../../components/modalUser";
import {useNavigate} from "react-router-dom";

export default function SettingUser() {
  const { isLoad, isLoaded,profile } = useContexts();
  const [userList, setUserList] = useState<Array<User>>([]);
  const [search, setSearch] = useState("");
  // const [page, setPage] = useState(1);
  const filteredItems = userList.filter(
    (item) => (item.firstName && item.firstName.toString().includes(search)) ||
              (item.lastName && item.lastName.toString().includes(search)) ||
              (item.username && item.username.toString().includes(search))
  );
  const [openFormUser, setOpenFormUser] = useState<{
    show: boolean;
    data: User | null;
    saved: boolean;
    userId: number;
  }>({ show: false, data: null, saved: false, userId: 0 });

  const navigate = useNavigate();
  useEffect(() => {
    getUserList().catch(console.error);
    if((profile?.sub !== '0')){
         navigate("/setting")
    }
  }, []);


  useEffect(() => {
    if(openFormUser.saved){
      getUserList().catch(console.error);
      setOpenFormUser((prev) => ({
        ...prev,
        saved: false,
      }))
    }
 }, [openFormUser.saved]);

  const getUserList = async () => {
    isLoad();
    setUserList(await apiService.userList(search));
    isLoaded();
  };

  const removeUser = async (data: User) => {
    await Swal.fire({
      title: `ต้องการลบ User: ${data.username}`,
      html: `<h5>${data.firstName} ${data.lastName}</h5>`,
      showCancelButton: true,
      confirmButtonText: "ลบ",
      cancelButtonText: "ปิด",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const res = await apiService.removeUser(data.userID);
        if (res.success === true) {
          getUserList().then();
        }
        Alert(res.success, res.message);
      }
    });
  };

  const setRoleUser =async (data: User,status:string) => {
    data.role = status;
    const res = await apiService.saveUser( data );
    if ( res.success === true ) {
      getUserList().then();
    }
    Alert(res.success, res.message);
  }

    const setChPass =async (data: User,status:number) => {
        data.changPass = status;
        const res = await apiService.saveUser( data );
        if ( res.success === true ) {
            getUserList().then();
        }
        Alert(res.success, res.message);
    }

  // return (
  //   <Container className={"mw-100"}>
  //
  //     </Container>
  // );

  return (
      <Container className={"mw-100"}>
        <div  className="tab-content" >
          <h2>รายการผู้ใช้งาน</h2>
             <div  className="mb-2">ค้นหาข้อมูลได้จาก ชื่อ และ นามสกุล</div>
             <div className="mb-2 row">
               <div className={"col-10"}>
               <input className={"form-control"} type="text" placeholder="ค้นหา.." onChange={(e) => setSearch(e.target.value)} />
               </div>
               <div className={"col-2 d-grid"}>
                 <Button variant="success" onClick={() => setOpenFormUser({ show: true, data: null, saved: false, userId: 0 })}><PersonFillAdd /> เพิ่มข้อมูล</Button>
               </div>
             </div>
            <Table striped bordered hover>
                <thead>
                <tr>
                    <th>Username</th>
                    <th>ชื่อ</th>
                    <th>สกุล</th>
                    <th>Site</th>
                    <th>Sub Site</th>
                    <th>Role</th>
                    <th>แก้ไข</th>
                </tr>
                </thead>
                <tbody>
                {filteredItems.length > 0 && filteredItems.map((r) => (
                    <tr>
                        <td>{r.username}{' '}
                            {(r.changPass === 0 ?
                                (<Button title={"set to Chang Password"} variant="success" size="sm" onClick={() => setChPass(r,1).then()}>
                                    <Check2Square />
                                </Button>) :
                                (<Button title={"set to un Chang"} variant="warning" size="sm" onClick={() => setChPass(r,0).then()}>
                                    <ChatSquareDots />
                                </Button>))
                            }</td>
                        <td>{r.firstName}</td>
                        <td>{r.lastName}</td>
                        <td>{r.site}</td>
                        <td>{r.sub}</td>
                        <td className={""}>
                            {r.role === "admin" ? 'Admin' : 'Member'}
                            {" "}
                            {profile?.userID != r.userID ?
                                (r.role === "admin" ?
                                (<Button title={"set to member"} variant="success" size="sm" onClick={() => setRoleUser(r,"member").then()}>
                                <People />
                            </Button>) :
                                (<Button title={"set to admin"} variant="primary" size="sm" onClick={() => setRoleUser(r,"admin").then()}>
                                <People />
                            </Button>))
                                :''}
                        </td>
                        <td className={"text-center"}>
                            <Button
                                size="sm"
                                onClick={() => setOpenFormUser({ show: true, data: r, saved: false, userId: r.userID })}
                            >
                                <Pen />
                            </Button>
                            {" "}
                            {profile?.userID != r.userID ?
                                <Button variant="danger" size="sm" onClick={() => removeUser(r).then()}>
                                    <Trash/>
                                </Button> : ''
                            }
                        </td>
                    </tr>
                ))}
                </tbody>
            </Table>
          <ModalUser
              show={openFormUser.show}
              data={openFormUser.data}
              handleClose={() =>
                  setOpenFormUser((prev) => ({
                    ...prev,
                    show: false,
                  }))
              }
              saved={() =>
                  setOpenFormUser((prev) => ({
                    ...prev,
                    saved: true,
                  }))}
              setUserId={(userId: number) =>
                  setOpenFormUser((prev) => ({
                    ...prev,
                    userId: userId,
                  }))
              }
          />
        </div>
      </Container>
  );
}
